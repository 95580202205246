<!-- Overlay -->
<div class="h-full w-full bg-black bg-opacity-20 fixed left-24 top-0 z-20 fade-in-25" (click)="close()"></div>
<!-- Sidebar -->
<aside class="struct-sidebar h-full bg-white overflow-x-hidden overflow-y-auto struct-scrollbar z-20 absolute left-16 md:left-24 fade-in-25">
    <div class="flex flex-col h-full w-screen 2xl:w-96">
        <div class="flex-grow">
            <section>
                <header class="relative flex h-20 flex-shrink-0 items-center" *ngIf="(menuComponent?.hideDefaultHeader ?? false) !== true">
                    <div class="flex flex-row md:min-w-0 flex-1 items-center justify-between px-2">
                        <div class="min-w-0 flex-1 flex grow items-center animate-in slide-in-from-bottom-2 duration-400">
                            <span class="text-lg font-medium ml-2 truncate" [translate]="menuTitle"></span>
                        </div>
                        <div class="md:ml-10 flex flex-shrink-0 items-center animate-in slide-in-from-bottom-2 duration-800">
                            <struct-icon-button icon="remove" size="16" #tooltip="matTooltip" matTooltip="Close" [matTooltipPosition]="'below'" (clicked)="close()"></struct-icon-button>
                        </div>
                    </div>
                </header>
                <div class="animate-in slide-in-from-bottom-4 duration-800 ">
                    <ng-template structDynamicHost></ng-template>
                </div>
            </section>
        </div>
    </div>
  <!-- Small arrow -->
  <!-- <div class="overflow-hidden inline-block z-50 fixed left-24 transition-all duration-200 -translate-x-full -mt-2"
    [ngStyle]="{'top.px': yOffset}">
    <img src="/assets/images/menu-arrow_50x50.png" width="25" height="25" />
  </div> -->
</aside>
