
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataElementReference } from "@struct/models/struct/app/domain/attributes/data-element-reference";
import { CreateGlobalListCommand } from "@struct/models/struct/app/domain/globallists/commands/create-global-list-command";
import { CreateGlobalListFolderCommand } from "@struct/models/struct/app/domain/globallists/commands/create-global-list-folder-command";
import { UpdateGlobalListCommand } from "@struct/models/struct/app/domain/globallists/commands/update-global-list-command";
import { UpdateGlobalListFolderCommand } from "@struct/models/struct/app/domain/globallists/commands/update-global-list-folder-command";
import { GlobalList } from "@struct/models/struct/app/domain/globallists/global-list";
import { GlobalListFolder } from "@struct/models/struct/app/domain/globallists/global-list-folder";
import { GlobalListReference } from "@struct/models/struct/app/domain/globallists/global-list-reference";
import { GlobalListSearchResult } from "@struct/models/struct/app/domain/globallists/global-list-search-result";
import { StructApp } from "@struct/models/struct/app/domain/shared/struct-app";
import { GlobalListSearchModel } from "@struct/models/struct/app/frontendapi/models/globallist/global-list-search-model";
import { GlobalListValueSearchModel } from "@struct/models/struct/app/frontendapi/models/globallist/global-list-value-search-model";
import { Query } from "@struct/models/struct/shared/search/query";

@Injectable({ providedIn: "root" })
export class GlobalListsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getGlobalListReferences(): Observable<GlobalListReference[]> {
    return this.http.get<GlobalListReference[]>(`${this.apiUrl}globallistreferences`, this.httpOptions );
  }

  getGlobalListSearchModels(): Observable<GlobalListSearchModel[]> {
    return this.http.get<GlobalListSearchModel[]>(`${this.apiUrl}globallistsearchmodels`, this.httpOptions );
  }

  deleteGlobalList(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}globallists/${uid}`, this.httpOptions );
  }

  createGlobalList(command: CreateGlobalListCommand): Observable<GlobalList> {
    return this.http.post<GlobalList>(`${this.apiUrl}globallists`, command, this.httpOptions);
  }

  updateGlobalList(command: UpdateGlobalListCommand): Observable<GlobalList> {
    return this.http.put<GlobalList>(`${this.apiUrl}globallists`, command, this.httpOptions);
  }

  getGlobalListsForApp(app: StructApp): Observable<GlobalListReference[]> {
    return this.http.get<GlobalListReference[]>(`${this.apiUrl}globallistreferences/${app}`, this.httpOptions );
  }

  getGlobalList(uid: string): Observable<GlobalList> {
    return this.http.get<GlobalList>(`${this.apiUrl}globallists/${uid}`, this.httpOptions );
  }

  getAliases(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}globallists/aliases`, this.httpOptions );
  }

  getAvailableFields(globalListUid: string): Observable<DataElementReference[]> {
    return this.http.get<DataElementReference[]>(`${this.apiUrl}globallists/availablefields?globalListUid=${globalListUid}`, this.httpOptions );
  }

  hasCreateAccessToGlobalList(uid: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}hasCreateAccess/${uid}`, this.httpOptions );
  }

  hasViewAccessToAnyGlobalList(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}globallists/hasViewAccess`, this.httpOptions );
  }

  searchGlobalList(globalListUid: string, searchModel: GlobalListValueSearchModel): Observable<GlobalListSearchResult> {
    return this.http.post<GlobalListSearchResult>(`${this.apiUrl}globallists/${globalListUid}/search`, searchModel, this.httpOptions);
  }

  getAllGlobalListValueUids(globalListUid: string, query: Query): Observable<string[]> {
    return this.http.post<string[]>(`${this.apiUrl}globallists/${globalListUid}/search/valueuids`, query, this.httpOptions);
  }

  getGlobalListFolders(): Observable<GlobalListFolder[]> {
    return this.http.get<GlobalListFolder[]>(`${this.apiUrl}globallistfolders`, this.httpOptions );
  }

  getGlobalListFolder(uid: string): Observable<GlobalListFolder> {
    return this.http.get<GlobalListFolder>(`${this.apiUrl}globallistfolders/${uid}`, this.httpOptions );
  }

  createGlobalListFolder(command: CreateGlobalListFolderCommand): Observable<GlobalListFolder> {
    return this.http.post<GlobalListFolder>(`${this.apiUrl}globallistfolders`, command, this.httpOptions);
  }

  updateGlobalListFolder(command: UpdateGlobalListFolderCommand): Observable<GlobalListFolder> {
    return this.http.put<GlobalListFolder>(`${this.apiUrl}globallistfolders`, command, this.httpOptions);
  }

  deleteGlobalListFolder(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}globallistfolders/${uid}`, this.httpOptions );
  }
}

