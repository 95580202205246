import { RouterModule, Routes } from '@angular/router';
import { AttributesCreateComponent } from './attributes/create/attributes-create.component';
import { ListPageComponent } from './list-page/list-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProfileComponent } from './profile/profile.component';
import { ShellComponent } from './shell/shell.component';
import { StructServicesComponent } from './struct-service/struct-services/struct-services.component';
import { CreateSubscriptionPlanComponent } from './subscription-plan/create-subscription-plan/create-subscription-plan.component';
import { EditSubscriptionPlanComponent } from './subscription-plan/edit-subscription-plan/edit-subscription-plan.component';
import { SubscriptionPlansComponent } from './subscription-plan/subscription-plans/subscription-plans.component';
import { CreateSubscriptionComponent } from './subscription/create-subscription/create-subscription.component';
import { EditSubscriptionComponent } from './subscription/edit-subscription/edit-subscription.component';
import { SubscriptionsComponent } from './subscription/subscriptions/subscriptions.component';
import { CreateTenantComponent } from './tenant/create-tenant/create-tenant.component';
import { CloneTenantComponent } from './tenant/clone-tenant/clone-tenant.component';
import { EditTenantComponent } from './tenant/edit-tenant/edit-tenant.component';
import { TenantsComponent } from './tenant/tenants/tenants.component';
import { FailedEmailValidationComponent } from './failed-email-validation/failed-email-validation.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { AcceptInviteComponent } from './authentication/accept-invite/accept-invite.component';
import { AuthenticatedGuard } from './authentication/guards/authenticated.guard';
import { UsersListComponent } from './users/users-list/users-list.component';
import { OrganizationsListComponent } from './organization/organizations/organizations-list.component';
import { AdminRoleGuard } from './authentication/guards/admin.guard';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { DatabaseTemplateListComponent } from './database-templates/database-template-list/database-template-list.component';
import { CreateOrganizationComponent } from './organization/create-organization/create-organization.component';
import { UpdateOrganizationCommand } from '@struct/models/accountmanagement/domain/organizations/commands/update-organization-command';
import { OrganizationsEditComponent } from './organization/edit-organization/edit-organization.component';
import { SignInWithMicrosoftCallbackComponent } from './authentication/sign-in-with-microsoft-callback/sign-in-with-microsoft-callback.component';
import { SignInWithMicrosoftComponent } from './authentication/sign-in-with-microsoft/sign-in-with-microsoft.component';
import { RegisterWithMicrosoftCallbackComponent } from './authentication/register-with-microsoft-callback/register-with-microsoft-callback.component';
import { ChangeSignInToMicrosoftCallbackComponent } from './authentication/change-sign-in-to-microsoft-callback/change-sign-in-to-microsoft-callback.component';
import { MyTenantsListComponent } from './mytenants/mytenants/mytenants-list.component';
import { UserRoleGuard } from '@struct/auth/guards/user.guard';
import { MyOrganizationsEditComponent } from './myorganization/edit-organization/edit-myorganization.component';
import { MyTenantCreateTenantComponent } from './mytenants/mytenant-create-tenant/mytenant-create-tenant.component';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/profile', pathMatch: 'full' },
      {
        path: 'sign-in',
        component: SignInComponent,
      },
      {
        path: 'sign-in-with-microsoft-callback',
        component: SignInWithMicrosoftCallbackComponent,
      },
      {
        path: 'change-sign-in-to-microsoft-callback',
        component: ChangeSignInToMicrosoftCallbackComponent,
      },
      {
        path: 'register-with-microsoft-callback',
        component: RegisterWithMicrosoftCallbackComponent,
      },
      {
        path: 'sign-in-with-microsoft',
        component: SignInWithMicrosoftComponent,
      },
      {
        path: 'accept-invite',
        component: AcceptInviteComponent,
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'users',
        component: UsersListComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'users/:uid',
        component: UserEditComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'services',
        component: StructServicesComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'subscription-plans',
        component: SubscriptionPlansComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'subscription-plans/create',
        component: CreateSubscriptionPlanComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'subscription-plans/:uid',
        component: EditSubscriptionPlanComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'subscriptions/create',
        component: CreateSubscriptionComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'subscriptions/:uid',
        component: EditSubscriptionComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'databasetemplates',
        component: DatabaseTemplateListComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'organizations',
        component: OrganizationsListComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard]
      },
      {
        path: 'organizations/create',
        component: CreateOrganizationComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard]
      },{
        path: 'organizations/:uid',
        component: OrganizationsEditComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard]
      },
      {
        path: 'mytenants',
        component: MyTenantsListComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard]
      },
      {
        path: 'mytenants/create/:uid',
        component: MyTenantCreateTenantComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard]
      },
      {
        path: 'myorganization',
        component: MyOrganizationsEditComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard]
      },
      {
        path: 'tenants',
        component: TenantsComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'tenants/create',
        component: CreateTenantComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'tenants/clone/:uid',
        component: CloneTenantComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      {
        path: 'tenants/:uid',
        component: EditTenantComponent,
        canActivate: [AuthenticatedGuard, AdminRoleGuard],
      },
      { path: 'shell', component: ShellComponent },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthenticatedGuard]
       },
      { path: 'product-page', component: ProductPageComponent },
      { path: 'list-page', component: ListPageComponent },
      { path: 'attributes/create', component: AttributesCreateComponent },
      { path: 'failedverification', component: FailedEmailValidationComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
    ],
  },
];
export const routing = RouterModule.forRoot(appRoutes);
