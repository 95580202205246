import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiModule } from '@struct/ui/ui.module';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { AccountsListContentComponent } from '../../accounts-ui/accounts-list/accounts-list-content/accounts-list-content.component';
import { AccountsListEmptyComponent } from '../../accounts-ui/accounts-list/accounts-list-empty/accounts-list-empty.component';
import { AccountsListItemBadgeComponent } from '../../accounts-ui/accounts-list/accounts-list-item-badge/accounts-list-item-badge.component';
import { AccountsListItemDescriptionComponent } from '../../accounts-ui/accounts-list/accounts-list-item-description/accounts-list-item-description.component';
import { AccountsListItemComponent } from '../../accounts-ui/accounts-list/accounts-list-item/accounts-list-item.component';
import { AccountsListSearchComponent } from '../../accounts-ui/accounts-list/accounts-list-search/accounts-list-search.component';
import { AccountsListComponent } from '../../accounts-ui/accounts-list/accounts-list/accounts-list.component';
import { SubscriptionPlanApiService } from '@struct/services/account-management';
import { SubscriptionPlan } from '@struct/models/accountmanagement/domain/subscriptions';
import { Router } from '@angular/router';

@Component({
  selector: 'accounts-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsHeaderComponent,
    AccountsListComponent,
    AccountsListSearchComponent,
    AccountsListEmptyComponent,
    AccountsListItemDescriptionComponent,
    AccountsListItemComponent,
    AccountsListContentComponent,
    AccountsListItemBadgeComponent,
  ],
})
export class SubscriptionPlansComponent implements OnInit, OnDestroy {
  subscriptionPlans: SubscriptionPlan[];
  allSubscriptionPlans: SubscriptionPlan[];
  searchTerm: string;

  private destroyed$ = new ReplaySubject();

  constructor(private router: Router, private subscriptionplanApi: SubscriptionPlanApiService) {
    this.allSubscriptionPlans = [];
    this.subscriptionPlans = [];
    this.searchTerm = '';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.allSubscriptionPlans = [];

    this.subscriptionplanApi
      .getSubscriptionPlans()
      .subscribe((x: SubscriptionPlan[]) => {
        this.allSubscriptionPlans = x;
        this.search();
      });
  }
  createSubscriptionPlan(): void {
    this.router.navigate(['/subscription-plans/create']);
  }

  public searchChanged(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.search();
  }

  public search(): void {
    if (this.searchTerm !== null && this.searchTerm.length > 0) {
      this.subscriptionPlans = this.allSubscriptionPlans.filter(x => x.name !== null && x.name.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()) !== -1);
    } else {
      this.subscriptionPlans = Object.assign([], this.allSubscriptionPlans);
    }
  }
}
