
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateSavedSearchCommand } from "@struct/models/struct/app/domain/savedsearch/commands/create-saved-search-command";
import { CreateSavedSearchFolderCommand } from "@struct/models/struct/app/domain/savedsearch/commands/create-saved-search-folder-command";
import { MoveSavedSearchCommand } from "@struct/models/struct/app/domain/savedsearch/commands/move-saved-search-command";
import { MoveSavedSearchFolderCommand } from "@struct/models/struct/app/domain/savedsearch/commands/move-saved-search-folder-command";
import { RenameSavedSearchCommand } from "@struct/models/struct/app/domain/savedsearch/commands/rename-saved-search-command";
import { RenameSavedSearchFolderCommand } from "@struct/models/struct/app/domain/savedsearch/commands/rename-saved-search-folder-command";
import { SortSavedSearchFolderChildrenCommand } from "@struct/models/struct/app/domain/savedsearch/commands/sort-saved-search-folder-children-command";
import { UpdateSavedSearchCommand } from "@struct/models/struct/app/domain/savedsearch/commands/update-saved-search-command";
import { UpdateSavedSearchFolderCommand } from "@struct/models/struct/app/domain/savedsearch/commands/update-saved-search-folder-command";
import { SavedSearch } from "@struct/models/struct/app/domain/savedsearch/saved-search";
import { AggregationFilter } from "@struct/models/struct/app/domain/search/aggregation-filter";
import { SearchResult } from "@struct/models/struct/app/domain/search/search-result";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { CategorySearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/category-search-result-item-model";
import { SavedSearchSortModel } from "@struct/models/struct/app/frontendapi/models/search/saved-search-sort-model";
import { SavedSearchesModel } from "@struct/models/struct/app/frontendapi/models/search/saved-searches-model";
import { SearchModel } from "@struct/models/struct/app/frontendapi/models/search/search-model";
import { SimpleAssetSearchModel } from "@struct/models/struct/app/frontendapi/models/search/simple-asset-search-model";
import { SimpleAssetSearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/search/simple-asset-search-result-item-model";
import { SimpleCategorySearchModel } from "@struct/models/struct/app/frontendapi/models/search/simple-category-search-model";
import { SimpleProductSearchModel } from "@struct/models/struct/app/frontendapi/models/search/simple-product-search-model";
import { SimpleProductSearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/search/simple-product-search-result-item-model";
import { SimpleVariantGroupSearchModel } from "@struct/models/struct/app/frontendapi/models/search/simple-variant-group-search-model";
import { SimpleVariantGroupSearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/search/simple-variant-group-search-result-item-model";
import { SimpleVariantSearchModel } from "@struct/models/struct/app/frontendapi/models/search/simple-variant-search-model";
import { SimpleVariantSearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/search/simple-variant-search-result-item-model";
import { DataField } from "@struct/models/struct/shared/search/data-field";

@Injectable({ providedIn: "root" })
export class SearchApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  createSavedSearchFolder(command: CreateSavedSearchFolderCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}search/savedsearchfolder`, command, this.httpOptions);
  }

  updateSavedSearchFolder(command: UpdateSavedSearchFolderCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}search/savedsearchfolder`, command, this.httpOptions);
  }

  moveSavedSearchFolder(command: MoveSavedSearchFolderCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}search/savedsearchfolder/move`, command, this.httpOptions);
  }

  renameSavedSearchFolder(command: RenameSavedSearchFolderCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}search/savedsearchfolder/rename`, command, this.httpOptions);
  }

  deleteSavedSearchFolder(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}search/savedsearchfolder/${uid}`, this.httpOptions );
  }

  createSavedSearch(command: CreateSavedSearchCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}search/savedsearch`, command, this.httpOptions);
  }

  getSavedSearch(uid: string): Observable<SavedSearch> {
    return this.http.get<SavedSearch>(`${this.apiUrl}search/savedsearch/${uid}`, this.httpOptions );
  }

  moveSavedSearch(command: MoveSavedSearchCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}search/savedsearch/move`, command, this.httpOptions);
  }

  sortSavedSearchFolderChildren(command: SortSavedSearchFolderChildrenCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}search/savedsearch/sortfolderchildren`, command, this.httpOptions);
  }

  renameSavedSearch(command: RenameSavedSearchCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}search/savedsearch/rename`, command, this.httpOptions);
  }

  updateSavedSearch(command: UpdateSavedSearchCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}search/savedsearch`, command, this.httpOptions);
  }

  deleteSavedSearch(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}search/savedsearch/${uid}`, this.httpOptions );
  }

  getSavedSearchesForMe(): Observable<SavedSearchesModel> {
    return this.http.get<SavedSearchesModel>(`${this.apiUrl}search/savedsearchesforme`, this.httpOptions );
  }

  updateSavedSearchSorting(model: SavedSearchSortModel[]): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}search/updatesavedsearchsorting`, model, this.httpOptions);
  }

  searchCategoriesByName(model: SimpleCategorySearchModel): Observable<CategorySearchResultItemModel[]> {
    return this.http.post<CategorySearchResultItemModel[]>(`${this.apiUrl}search/searchcategoriesbyname`, model, this.httpOptions);
  }

  getCategoriesByIds(categoryIds: number[], languageId: number): Observable<CategorySearchResultItemModel[]> {
    return this.http.post<CategorySearchResultItemModel[]>(`${this.apiUrl}search/getcategoriesbyids?languageId=${languageId}`, categoryIds, this.httpOptions);
  }

  searchProductsByName(model: SimpleProductSearchModel): Observable<SimpleProductSearchResultItemModel[]> {
    return this.http.post<SimpleProductSearchResultItemModel[]>(`${this.apiUrl}search/searchproductsbyname`, model, this.httpOptions);
  }

  getAvailableEntityTypes(): Observable<StructEntityType[]> {
    return this.http.get<StructEntityType[]>(`${this.apiUrl}search/availableentitytypes`, this.httpOptions );
  }

  getProductsByIds(productIds: number[], languageId: number): Observable<SimpleProductSearchResultItemModel[]> {
    return this.http.post<SimpleProductSearchResultItemModel[]>(`${this.apiUrl}search/getproductsbyids?languageId=${languageId}`, productIds, this.httpOptions);
  }

  searchVariantGroupsByName(model: SimpleVariantGroupSearchModel): Observable<SimpleVariantGroupSearchResultItemModel[]> {
    return this.http.post<SimpleVariantGroupSearchResultItemModel[]>(`${this.apiUrl}search/searchvariantgroupssbyname`, model, this.httpOptions);
  }

  getVariantGroupsByIds(variantGroupIds: number[], languageId: number): Observable<SimpleVariantGroupSearchResultItemModel[]> {
    return this.http.post<SimpleVariantGroupSearchResultItemModel[]>(`${this.apiUrl}search/getvariantgroupsbyids?languageId=${languageId}`, variantGroupIds, this.httpOptions);
  }

  searchVariantsByName(model: SimpleVariantSearchModel): Observable<SimpleVariantSearchResultItemModel[]> {
    return this.http.post<SimpleVariantSearchResultItemModel[]>(`${this.apiUrl}search/searchvariantsbyname`, model, this.httpOptions);
  }

  getVariantsByIds(variantIds: number[], languageId: number): Observable<SimpleVariantSearchResultItemModel[]> {
    return this.http.post<SimpleVariantSearchResultItemModel[]>(`${this.apiUrl}search/getvariantsbyids?languageId=${languageId}`, variantIds, this.httpOptions);
  }

  searchAssetsByName(model: SimpleAssetSearchModel): Observable<SimpleAssetSearchResultItemModel[]> {
    return this.http.post<SimpleAssetSearchResultItemModel[]>(`${this.apiUrl}search/searchassetsbyname`, model, this.httpOptions);
  }

  getAssetsByIds(assetIds: number[]): Observable<SimpleAssetSearchResultItemModel[]> {
    return this.http.post<SimpleAssetSearchResultItemModel[]>(`${this.apiUrl}search/getassetsbyids`, assetIds, this.httpOptions);
  }

  search(model: SearchModel): Observable<SearchResult> {
    return this.http.post<SearchResult>(`${this.apiUrl}search/search`, model, this.httpOptions);
  }

  getAllUidsInSearchResult(model: SearchModel): Observable<string[]> {
    return this.http.post<string[]>(`${this.apiUrl}search/alluidsinsearchresult`, model, this.httpOptions);
  }

  getAvailableFields(entityType: StructEntityType): Observable<DataField[]> {
    return this.http.post<DataField[]>(`${this.apiUrl}search/availablefields?entityType=${entityType}`, null, this.httpOptions);
  }

  getFilter(entityType: StructEntityType, fieldUid: string): Observable<{ [key: string]: number }> {
    return this.http.get<{ [key: string]: number }>(`${this.apiUrl}search/aggregations/${entityType}/${fieldUid}`, this.httpOptions );
  }

  getFilters(entityType: StructEntityType, fieldUids: string[]): Observable<AggregationFilter[]> {
    return this.http.post<AggregationFilter[]>(`${this.apiUrl}search/aggregations/${entityType}`, fieldUids, this.httpOptions);
  }
}

